import React from 'react';
import Container from '../components/Container';
import SEO from '../components/SEO';

//

const NotFoundPage = () => (
  <Container>
    <div style={{ paddingTop: '2rem', paddingBottom: '2rem' }}>
      <h1>404! This page could not be found</h1>
      <SEO title="Page Missing" />
    </div>
  </Container>
);

export default NotFoundPage;
